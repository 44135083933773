<template>
  <v-app class="rs-lead-sheet">
    <v-main v-if="loading">
      <v-container>
        <div class="h-100 d-flex align-center justify-center">
          <v-progress-circular
            indeterminate
            size="70"
            color="primary"
          ></v-progress-circular>
        </div>
      </v-container>
    </v-main>
    <v-main v-if="!loading && report">
      <v-toolbar
        ref="toolbar"
        flat
        color="rgba(240, 242, 245, 0.8)"
        class=""
        style="position: sticky; top: 0; z-index: 1; backdrop-filter: blur(4px)"
      >
        <v-container>
          <v-row>
            <v-col class="d-flex col-12 col-sm-6">
              <router-link to="/">
                <v-img
                  to="/"
                  lazy-src="@/assets/risk-score-logo-full-blue.svg"
                  :max-width="220"
                  src="@/assets/risk-score-logo-full-blue.svg"
                  class="rs-logo"
                  contain
                ></v-img>
              </router-link>
            </v-col>
            <v-col class="d-flex align-center justify-end col-sm-6 col-12">
              <div class="mr-4">
                {{ reportInput.advisor.firstName }}
                {{ reportInput.advisor.lastName }}<br />
                <smalL>{{ reportInput.advisor.companyName }}</smalL>
              </div>

              <v-btn icon x-small class="mr-4">
                <v-icon>mdi-login</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-toolbar>
      <v-container>
        <main>
          <v-row>
            <v-col>
              <v-card
                ref="headerRow"
                v-intersect="{
                  handler: onIntersect,
                  options: {
                    threshold: [
                      0, 0.125, 0.25, 0.375, 0.5, 0.625, 0.75, 0.875, 1,
                    ],
                  },
                }"
                outlined
                elevation="12"
                class="rs-lead-sheet__bg d-flex justify-space-between"
                dark
                min-height="250"
              >
                <v-card-title
                  class="
                    text-uppercase
                    flex-column
                    align-start align-self-center
                  "
                  style="white-space: nowrap"
                >
                  <span>prospect</span>
                  <h1 class="my-2">Lead Sheet</h1>
                  <small
                    >Generated: {{ report._generated }}</small
                  ></v-card-title
                >
                <!-- </v-img> -->
                <div
                  class="
                    pa-4
                    flex-grow-1
                    d-flex
                    flex-column
                    justify-center
                    align-center
                  "
                >
                  <div style="width: 100px" class="mb-4">
                    <GenderAgeAvatarStandalone
                      :age="reportInput.financial_profile_info.current_age"
                      :gender="reportInput.gender"
                    />
                  </div>
                  <div class="mb-1 text-h6 font-weight-bold">
                    {{ reportInput.prospect.first_name }}
                    {{ reportInput.prospect.last_name }}
                  </div>
                  <div class="mb-1">{{ reportInput.prospect.email }}</div>
                  <div>{{ reportInput.prospect.phone }}</div>
                </div>

                <div
                  class="pa-4 d-flex flex-column justify-center align-center"
                >
                  <v-list color="transparent">
                    <v-list-item class="mb-2 justify-end">
                      <v-btn text :href="getReportLink" target="_blank">
                        View Score Report
                        <v-icon small class="ml-2"
                          >mdi-arrow-right</v-icon
                        ></v-btn
                      >
                    </v-list-item>
                    <v-list-item class="mb-2 justify-end">
                      <v-btn text>
                        View Detail Page
                        <v-icon small class="ml-2"
                          >mdi-arrow-right</v-icon
                        ></v-btn
                      >
                    </v-list-item>
                    <v-list-item class="mb-2 justify-end">
                      <v-btn text>
                        Email Prospect
                        <v-icon small class="ml-2"
                          >mdi-arrow-right</v-icon
                        ></v-btn
                      >
                    </v-list-item>
                  </v-list>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <!-- <v-col class="col-auto">
              <v-navigation-drawer
                floating
                permanent
                color="transparent"
                class="pt-2 rs-lead-sheet__nav"
                style="transform: none"
              >
                <v-list
                  class="py-0"
                  color="#24262D"
                  dark
                  style="
                    position: fixed;
                    transition: all 0.15s linear;
                    z-index: 1;
                  "
                  :style="'top:' + floatingAvatarTop"
                  min-width="275"
                >
                  <v-fade-transition>
                    <v-list-item class="pa-0" v-if="showAvatar">
                      <v-card
                        transition="fade-transition"
                        outlined
                        color="blue"
                        dark
                        elevation="8"
                        class="rs-lead-sheet__bg"
                        style="background-attachment: initial; width: 100%"
                      >
                        <div class="pa-4 d-flex flex-column align-center">
                          <div style="width: 100px" class="mb-4">
                            <GenderAgeAvatarStandalone
                              :age="
                                reportInput.financial_profile_info.current_age
                              "
                              :gender="reportInput.gender"
                            />
                          </div>
                          <div class="mb-1 text-body-1 font-weight-bold">
                            {{ reportInput.prospect.first_name }}
                            {{ reportInput.prospect.last_name }}
                          </div>
                        </div>
                      </v-card>
                    </v-list-item>
                  </v-fade-transition>
                  <v-list-item
                    link
                    v-for="n in navItems"
                    :key="n.title"
                    @click.prevent="scrollToAnchor(n.to)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ n.title }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon small>mdi-arrow-right</v-icon>
                    </v-list-item-icon>
                  </v-list-item>

                  <v-divider color="grey" class="my-2"></v-divider>
                  <v-list-item class="mb-2">
                    <v-btn outlined block>Go to Dashboard</v-btn>
                  </v-list-item>
                </v-list>
              </v-navigation-drawer>
            </v-col> -->

            <v-col class="">
              <section id="scroll_score_results" class="mb-8">
                <v-card outlined>
                  <div class="mb-2 px-6 pt-4 pb-0 d-flex">
                    <h2 class="text-h5 font-weight-bold mb-0">
                      {{ reportInput.prospect.first_name }}'s Risk Score Results
                    </h2>
                  </div>
                  <v-card-text>
                    <v-row>
                      <v-col class="pa-4">
                        <div style="max-width: 400px" class="mx-auto">
                          <ReportCompareAnimationSideBySide
                            :prospectScore="reportInput.score"
                            :averageScore="reportInput.average_score_for_age"
                            colorScheme="light"
                          />
                        </div>
                      </v-col>
                      <v-col class="col-12 col-sm-6 col-md-8">
                        <v-row>
                          <v-col>
                            <v-list color="transparent">
                              <v-list-item>
                                <v-list-item-title>
                                  Age:
                                  <strong>{{
                                    reportInput.financial_profile_info
                                      .current_age
                                  }}</strong>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider></v-divider>
                              <v-list-item>
                                <v-list-item-title>
                                  Age Generation:
                                  <strong>{{ reportInput.generation }}</strong>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider></v-divider>
                              <v-list-item>
                                <v-list-item-title>
                                  Lifetime Benefit Cut:
                                  <strong>{{
                                    reportInput.social_security_cut_info
                                      .lifetime_benefit_cut
                                  }}</strong>
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-col>
                          <v-col class="px-8">
                            <v-list color="transparent">
                              <v-list-item>
                                <v-list-item-title>
                                  Estimated Benefits at Risk:
                                  <strong>{{
                                    reportInput.social_security_cut_info
                                      .promised_lifetime_total_benefits_post_reduction
                                  }}</strong>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider></v-divider>
                              <v-list-item>
                                <v-list-item-title>
                                  Financial Profile:
                                  <strong class="text-capitalize">{{
                                    reportInput.financial_profile_info.profile
                                  }}</strong>
                                </v-list-item-title>
                              </v-list-item>
                              <v-divider></v-divider>
                              <v-list-item>
                                <v-list-item-title>
                                  Likelihood to Receive Benefit Cut:
                                  <strong class="text-capitalize">{{
                                    reportInput.financial_profile_info
                                      .profile_likelihood
                                  }}</strong>
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <div class="d-flex justify-center">
                      <v-btn
                        small
                        text
                        @click.prevent="
                          scrollToAnchor('#scroll_survey_answers')
                        "
                        >Jump to Survey Answers
                        <v-icon small class="ml-4"
                          >mdi-arrow-down</v-icon
                        ></v-btn
                      >
                    </div>
                  </v-card-text>
                </v-card>
              </section>
              <section id="scroll_lead_intel" class="mb-8">
                <v-card outlined>
                  <div class="px-6 pt-4 pb-0 d-flex">
                    <h2 class="text-h5 font-weight-bold mb-0">Lead Intel</h2>
                  </div>
                  <div
                    v-for="rec in reportInput.product_recommendations"
                    :key="rec.id"
                  >
                    <v-card-text
                      v-for="(intel, i) in rec.intel"
                      :key="intel.id"
                    >
                      <v-alert
                        :color="i === 0 ? 'deep-purple lighten-2' : 'primary '"
                        dark
                        :icon="i === 0 ? 'mdi-lightbulb-on' : 'mdi-information'"
                        border="left"
                        prominent
                        class="mb-0"
                      >
                        {{ intel.text }}
                      </v-alert>
                    </v-card-text>
                  </div>
                </v-card>
              </section>

              <!-- Product Recommendations -->
              <section id="scroll_product_recommendations" class="mb-8">
                <v-card outlined class="pb-4">
                  <div class="px-6 pt-4 pb-0">
                    <p class="text-h5 font-weight-bold mb-2">
                      Product Recommendations
                    </p>
                    <p class="mb-6">
                      These are recommended products for the prospect and
                      suggested customized sales scripts.
                    </p>
                  </div>
                  <v-card-text
                    v-if="
                      !reportInput.product_recommendations ||
                      reportInput.product_recommendations.length <= 0
                    "
                  >
                    <v-alert type="warning">
                      There are no Product Recommendations for this prospect.
                    </v-alert>
                  </v-card-text>
                  <template v-else>
                    <div
                      v-for="rec in reportInput.product_recommendations"
                      :key="rec.id"
                      class="rs-lead-sheet__recommendation"
                    >
                      <!-- <v-card-text> -->
                      <v-card dark tile flat>
                        <v-card-title
                          class="ml-n9 font-weight-bold pa-0 text-body-1"
                          ><div
                            class="
                              primary
                              pl-2
                              pr-4
                              py-2
                              mr-6
                              rs-lead-sheet__product-header
                            "
                          >
                            <v-icon class="mr-3">mdi-widgets</v-icon>Product
                          </div>
                          {{ rec.name }} <v-spacer></v-spacer
                          ><v-btn text small
                            >Product Details<v-icon small class="ml-2"
                              >mdi-arrow-right</v-icon
                            ></v-btn
                          ></v-card-title
                        >
                      </v-card>
                      <p class="pa-4 pb-0">
                        {{ rec.intel[0].text }} Use the provided scripts to
                        start a conversation with the prospect.
                      </p>
                      <!-- </v-card-text> -->

                      <!-- 
                      <v-card-subtitle class="my-1 py-0"
                        >Customized Sales Scripts:</v-card-subtitle
                      > -->

                      <v-row class="pa-4">
                        <v-col
                          v-for="s in rec.scripts"
                          :key="s.id"
                          class="col-12 col-sm-6 d-flex flex-column"
                        >
                          <v-card
                            color="#24262D"
                            dark
                            flat
                            class="flex d-flex flex-column"
                          >
                            <v-card-title class="d-flex align-center">
                              <!-- <v-btn icon plain style="background-color: white">
                                <template v-if="handleGetSurveyIcon(s.topic)">
                                  <img :src="handleGetSurveyIcon(s.topic)" />
                                </template>

                                <v-icon v-else color="primary"
                                  >mdi-information-outline</v-icon
                                >
                              </v-btn> -->

                              <v-icon color="white"
                                >mdi-movie-edit-outline</v-icon
                              >
                              <v-spacer></v-spacer>

                              <v-chip
                                label
                                small
                                class="text-caption text-uppercase"
                                >script</v-chip
                              >
                            </v-card-title>
                            <div class="pa-4 flex">
                              {{ s.text }}
                            </div>
                            <v-divider></v-divider>
                            <v-card-actions
                              class="px-4 py-0"
                              style="background-color: #2d2f36"
                            >
                              <v-tooltip
                                dark
                                top
                                color="primary"
                                transition="slide-y-transition"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    class="mr-4"
                                    v-bind="attrs"
                                    v-on="on"
                                    ><v-icon>mdi-content-copy</v-icon></v-btn
                                  ></template
                                >
                                <span>Copy Script</span>
                              </v-tooltip>

                              <v-tooltip
                                dark
                                top
                                color="primary"
                                transition="slide-y-transition"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    class="mr-4"
                                    v-bind="attrs"
                                    v-on="on"
                                    ><v-icon>mdi-email</v-icon></v-btn
                                  ></template
                                >
                                <span>Email Script</span>
                              </v-tooltip>
                              <v-spacer></v-spacer>
                              <v-switch
                                color="primary"
                                inset
                                label="Used"
                              ></v-switch>
                            </v-card-actions>
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-list style="background-color: #f0f7ff" class="py-0">
                        <!-- <v-hover
                          v-for="s in rec.scripts"
                          :key="s.id"
                          v-slot="{ hover }"
                        >
                          <v-list-item
                            class="pb-4"
                            :style="[
                              hover ? { 'background-color': '#c9e4ff' } : '',
                            ]"
                          >
                            <v-icon
                              :color="hover ? 'primary' : ''"
                              class="mr-4"
                            >
                              {{
                                hover
                                  ? "mdi-arrow-right-drop-circle"
                                  : "mdi-arrow-right-drop-circle-outline"
                              }}
                            </v-icon>
                            <v-list-item-content class="text-body-1">
                              {{ s.text }}</v-list-item-content
                            >
                          </v-list-item>
                        </v-hover> -->
                      </v-list>
                    </div>
                  </template>
                </v-card>
              </section>

              <!-- Grid of Prospect Survey Answers -->
              <section id="scroll_survey_answers" class="mb-8">
                <v-card outlined>
                  <div class="mb-4 px-6 pt-4 pb-0 d-flex">
                    <h2 class="text-h5 font-weight-bold mb-0">
                      {{ reportInput.prospect.first_name }}'s Risk Score Survey
                      Answers
                    </h2>
                  </div>
                  <v-card-text>
                    <v-row>
                      <v-col
                        v-for="a in reportInput.prospect.raw_answers"
                        :key="a.id"
                        class="col-4 rs-lead-sheet__answer"
                      >
                        <!-- <v-card outlined class="mb-2 mr-4 h-100"> -->
                        <div
                          class="d-flex flex-column justify-center align-center"
                        >
                          <v-img
                            :src="handleGetSurveyIcon(a.param)"
                            max-width="56"
                            max-height="56"
                          />
                          <div class="mt-2 text-center">
                            <p
                              class="mb-0 text-caption font-weight-bold"
                              style="line-height: 1.3"
                            >
                              {{ a.param.replaceAll("_", "\n").toUpperCase() }}
                            </p>

                            <p
                              class="
                                mb-0
                                text-center text-body-1
                                font-weight-bold
                                primary--text
                                py-4
                                flex-grow-1
                              "
                            >
                              {{ a.value }}
                            </p>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </section>
            </v-col>
          </v-row>
          <Footer />
        </main>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapSurveyIcon } from "@/services/surveyIconMapping";
import Footer from "@/components/Footer";
import GenderAgeAvatarStandalone from "@/components/GenderAgeAvatarStandalone";
import ReportCompareAnimationSideBySide from "../components/ReportCompareAnimationSideBySide";

export default {
  components: {
    Footer,
    GenderAgeAvatarStandalone,
    ReportCompareAnimationSideBySide,
  },
  data() {
    return {
      isIntersecting: false,
      floatingAvatarTop: "auto",
      showAvatar: false,
      report: null,
      reportInput: null,
      loading: false,
      navItems: [
        {
          title: "Risk Score Results",
          to: "#scroll_score_results",
        },
        {
          title: "Lead Intel",
          to: "#scroll_lead_intel",
        },
        {
          title: "Product Recommendations",
          to: "#scroll_product_recommendations",
        },
        {
          title: "Survey Answers",
          to: "#scroll_survey_answers",
        },
      ],
    };
  },
  computed: {
    getReportLink() {
      return `${window.location.origin}/your-score-report/${this.reportInput.prospect.id}`;
    },
  },
  mounted() {
    const prospectId = this.$route.params.id;
    this.fetchReport(prospectId);
  },
  methods: {
    scrollToAnchor(to) {
      document.querySelector(to).scrollIntoView({
        behavior: "smooth",
      });
    },
    onIntersect(entries) {
      // More information about these options
      // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      const toolbarHeight = this.$refs.toolbar.$el.clientHeight;
      const intersectHeight = entries[0].intersectionRect.height;
      // const intersectTop = entries[0].intersectionRect.top;
      this.isIntersecting = entries[0].isIntersecting;
      // if intersecting and the height of the card is > the toolbar, add both;
      if (this.isIntersecting) {
        this.floatingAvatarTop = intersectHeight + toolbarHeight + 36 + "px";

        // console.log("intersecting");
        // if (intersectHeight === toolbarHeight) {
        //   console.log("EQUAL");
        //   this.floatingAvatarTop = toolbarHeight + 16 + "px";
        // } else if (
        //   intersectHeight > toolbarHeight &&
        //   entries[0].intersectionRatio === 1
        // ) {
        //   console.log("GREATER");
        //   this.floatingAvatarTop = intersectHeight + toolbarHeight + 16 + "px";
        // } else {
        //   console.log("LESS THAN");
        //   this.floatingAvatarTop = intersectHeight + 16 + "px";
        // }
      } else {
        this.floatingAvatarTop =
          intersectHeight +
          toolbarHeight +
          // this.$refs.headerRow.clientHeight +
          16 +
          "px";
      }

      if (entries[0].intersectionRatio >= 0.5) {
        this.showAvatar = false;
      } else {
        this.showAvatar = true;
      }
      // if (entries[0].isIntersecting) {
      //   this.floatingAvatarTop =
      //     this.$refs.toolbar.$el.clientHeight +
      //     entries[0].intersectionRect.height +
      //     16 +
      //     "px";
      // } else {
      //   this.floatingAvatarTop =
      //     this.$refs.toolbar.$el.clientHeight + 16 + "px";
      // }
    },
    handleGetSurveyIcon(param) {
      const iconFileName = mapSurveyIcon(param);
      if (iconFileName) {
        return require("@/assets/surveyIcons/" + iconFileName);
      }
    },
    fetchReport(prospectId) {
      // Fetch score by submitting scoring params
      this.loading = true;

      fetch(process.env.VUE_APP_SCORE_API + "score_report_v3", {
        method: "POST",
        headers: {
          // Authorization: `Bearer ${process.env.NEEDTOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(prospectId),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong fetching report", response);
          }
        })
        .then((data) => {
          if (data.success) {
            console.log("data report", data);
            if (!data.input || !data.input.product_recommendations) {
              this.message =
                "Sorry, we were unable to locate your report. Please refresh to try again or contact your advisor for assistance.";
            } else {
              this.report = data.report;
              this.reportInput = data.input;
            }
          } else {
            console.log("Error data:", data);
            throw new Error(data.message);
          }

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          if (!this.report) {
            this.message = error.message;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
section[id^="scroll"] {
  scroll-margin-top: 80px;
}
.rs-lead-sheet {
  &__product-header {
    position: relative;

    &:before {
      content: "";
      background: #4188ec;
      position: absolute;
      left: calc(100% - 1px);
      top: 0;
      height: 100%;
      width: 1rem;
      clip-path: polygon(100% 50%, 0 0, 0 100%);
    }
  }

  &__recommendation {
    &:not(:first-of-type) {
      margin-top: 3rem;
    }
  }

  &__bg {
    position: relative;
    background: linear-gradient(#18466f, #316eae);
    &:before {
      content: " ";
      position: absolute;
      height: 100%;
      width: 100%;
      background-image: linear-gradient(
        280deg,
        hsla(0, 0%, 100%, 0.07058823529411765) 40%,
        rgba(35, 77, 122, 0.00392156862745098) 0
      );
      background-repeat: no-repeat;
      background-position: 100% 0;
      background-size: 100%;
    }
  }

  &.theme--light.v-application {
    background-color: #f0f2f5;
  }

  &__answer {
    border-right: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    // every third
    &:nth-child(3n + 3) {
      border-right: none;
    }

    // last three
    &:nth-last-child(-n + 3) {
      border-bottom: none;
    }
  }
  /*
  &__nav {
    .v-list-item {
      &__icon {
        display: none;
      }

      &--active {
        .v-list-item__icon {
          display: block;
        }
      }
    }
  } */

  .theme--dark {
    .v-list-item--active,
    .v-list-item--active:hover {
      color: var(--v-primary-base);

      .v-list-item__title {
        font-weight: bold;
      }

      &:before {
        opacity: 0;
      }
    }
  }
}
</style>
